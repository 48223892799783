import { render, staticRenderFns } from "./PickUpRecord.vue?vue&type=template&id=3439b85a&scoped=true"
import script from "./PickUpRecord.vue?vue&type=script&lang=js"
export * from "./PickUpRecord.vue?vue&type=script&lang=js"
import style0 from "./PickUpRecord.vue?vue&type=style&index=0&id=3439b85a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3439b85a",
  null
  
)

export default component.exports