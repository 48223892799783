<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-13
 * @Description: 营销管理/平台营销工具/平台卡券活动/领取记录
-->
<template>
  <scroll-layout>
    <template #header>
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
      <ykc-search ref="YkcSearch" :data="searchItems" @click="handleSearchConfirm" />
    </template>
    <ykc-table
      ref="YkcTable"
      :data="tableData"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons">
      <template #title>
        <div>
          <span class="ykc-table-header-title">{{ tableTitle }}</span>
          <span class="ykc-table-header-subtitle">导入数量：{{ count }}</span>
        </div>
      </template>
      <template #headerButtons>
        <div class="table-header-button">
          <ykc-button type="plain" @click="exportBlob">导出</ykc-button>
        </div>
      </template>
      <ykc-pagination
        slot="pagination"
        ref="YkcPagination"
        :total="total"
        :current-page.sync="currentPage"
        @current-change="handlePaginationCurrentChange"
        :page-size.sync="pageSize"
        @size-change="handlePaginationSizeChange" />
    </ykc-table>
  </scroll-layout>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { couponActivity } from '@/service/apis';
  import { offlineExport } from '@/utils'; // 新增/编辑组件
  import { dictDesc } from '@/store/dict';

  export default {
    name: 'platformCouponActivity',
    components: {},
    computed: {
      ...mapGetters([]),
    },
    data() {
      return {
        count: 0,
        tableOperateButtons: [],
        activityId: '',
        activityType: '',
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        /**
         * 查询数据
         */
        searchItems: [],
        tableData: [],

        tableColumns: [
          { label: '领取用户', prop: 'userAccount' },
          { label: '归属客户', prop: 'userOrgName' },
          { label: '用户分组', prop: 'userGroupName' },
          { label: '活动名称', prop: 'activityName' },
          {
            label: '活动类型',
            prop: 'activityType',
            scopedSlots: {
              default: ({ row }) => {
                return dictDesc('activityTypes', row.activityType);
              },
            },
          },
          { label: '承担方', prop: 'activityInitiatorText' },
          { label: '参与时间', prop: 'receiveTime' },
        ],
        tableTitle: '领取记录清单',
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };
    },
    created() {
      this.activityId = this.$route.query.activityId || '';
      this.activityType = this.$route.query.activityType || '';
      if (this.activityType === '7') {
        // 拉新活动查询条件及列表配置项有所改动
        this.searchItems = [];
        this.tableColumns.splice(2, 1); // 删除用户分组
        this.tableColumns[0] = { label: '受邀请人', prop: 'userAccount' };
        this.tableColumns[1] = { label: '邀请人', prop: 'userOrgName' };
      } else {
        this.searchItems = [
          {
            comName: 'YkcDatePicker',
            type: 'daterange',
            format: 'yyyy-MM-dd',
            key: 'collectioTime',
            label: '领取时间',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '领取用户',
            placeholder: '请输入领取用户',
          },
          {
            comName: 'YkcInput',
            key: 'userOrgName',
            label: '归属客户',
            placeholder: '请输入归属客户',
          },
        ];
      }
      this.getData();
      this.queryForPageImportNumberApi();
    },
    methods: {
      /**
       * 点击查询
       * @param form
       */
      handleSearchConfirm(form) {
        this.currentPage = 1;
        const search = JSON.parse(JSON.stringify(form));
        if (search.collectioTime && search.collectioTime.length === 2) {
          [search.receiveTimeStart, search.receiveTimeEnd] = search.collectioTime;
        }

        delete search.receiveTimeStart;
        delete search.receiveTimeEnd;
        this.searchParams = search || {};
        this.getData();
      },
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
        console.log('列表按钮点击事件', button, event);
      },
      /**
       * 导出
       */
      exportBlob() {
        offlineExport({
          downloadType: 'marketing_export',
          jsonNode: {
            downloadKey: 'cardCouponActivityReceiveRecordList',
            activityId: this.activityId || '',
            ...this.searchParams,
          },
        });
      },
      /**
       * 获取导入数量
       */
      queryForPageImportNumberApi() {
        couponActivity
          .queryForPageImportNumber({
            activityId: this.activityId || '',
          })
          .then(res => {
            this.count = res || 0;
          });
      },

      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.currentPage = 1;
        this.pageSize = size;
        this.getData();
      },

      /**
       * @desc 获取数据
       * */
      getData() {
        const reqData = {
          ...(this.searchParams || {}),
          current: this.currentPage,
          size: this.pageSize,
        };
        reqData.activityId = this.activityId || '';
        couponActivity.couponReceiveRecord(reqData).then(res => {
          this.tableData = res.records || [];
          this.total = res.total;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table-header-subtitle {
    font-size: 12px;
    margin-left: 28px;
  }
</style>
